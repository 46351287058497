<template>
  <div class="container">
    <h1>{{ msg }}</h1>
    <p>
      For a guide and recipes on how to configure / customize this project,<br>
      check out the
      <a href="https://cli.vuejs.org" target="_blank" rel="noopener">vue-cli documentation</a>.
    </p>
    <h3>Installed CLI Plugins</h3>
    <ul class="list-group">
      <li class="list-group-item"><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-babel" target="_blank" rel="noopener">babel</a></li>
      <li class="list-group-item"><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-eslint" target="_blank" rel="noopener">eslint</a></li>
    </ul>
    <h3>Essential Links</h3>
    <ul class="list-group">
      <li class="list-group-item"><a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a></li>
      <li class="list-group-item"><a href="https://forum.vuejs.org" target="_blank" rel="noopener">Forum</a></li>
      <li class="list-group-item"><a href="https://chat.vuejs.org" target="_blank" rel="noopener">Community Chat</a></li>
      <li class="list-group-item"><a href="https://twitter.com/vuejs" target="_blank" rel="noopener">Twitter</a></li>
      <li class="list-group-item"><a href="https://news.vuejs.org" target="_blank" rel="noopener">News</a></li>
    </ul>
    <h3>Ecosystem</h3>
    <ul class="list-group">
      <li class="list-group-item"><a href="https://router.vuejs.org" target="_blank" rel="noopener">vue-router</a></li>
      <li class="list-group-item"><a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a></li>
      <li class="list-group-item"><a href="https://github.com/vuejs/vue-devtools#vue-devtools" target="_blank" rel="noopener">vue-devtools</a></li>
      <li class="list-group-item"><a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener">vue-loader</a></li>
      <li class="list-group-item"><a href="https://github.com/vuejs/awesome-vue" target="_blank" rel="noopener">awesome-vue</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  props: {
    msg: String
  }
}
</script>

<style scoped>
.home {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}
</style>
