<template>
  <footer class="footer">
    <div class="container">
      <p>备案信息: <a href="https://beian.miit.gov.cn">京ICP备2023001636号</a></p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'HomeFooter'
}
</script>

<style scoped>
.footer {
  background-color: #f8f9fa;
  padding: 20px 0;
  text-align: center;
}
</style>
