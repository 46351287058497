import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HelloWorld from "@/components/HelloWorld.vue";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeView,
        props: (route) => ({ msg: route.meta.msg }),
        meta: {msg: "Welcome to Tenacity Website!"}
    },
    {
        path: '/helloWorld',
        name: 'HelloWorld',
        component: HelloWorld,
        props: (route) => ({ msg: route.meta.msg }),
        meta: {msg: "Welcome to Tenacity Website!"}
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
